import { ProductEdition, UserPrivilege } from 'insightvm-ui-nav-menus';

export default ({
    admin: isAdmin = false,
    canUserSeeAdminTab = false,
    isCloudEnabled = false,
    productEdition,
    featureSet: { licensedOptions = [], ticketingSupported = false },
    oemConfig: { ticketingEnabled = false },
    userPrivileges = [],
    disabledFeatures = []
}) => {
    if (!Array.isArray(userPrivileges)) {
        return [];
    }

    let privileges = [
        UserPrivilege.HOME,
        UserPrivilege.ASSET,
        UserPrivilege.VULNERABILITY,
        licensedOptions.includes('INSIGHT_VM') ? UserPrivilege.ALL_POLICIES : UserPrivilege.POLICIES,
        UserPrivilege.REPORTS
    ];

    if (isCloudEnabled) {
        privileges = privileges.concat([UserPrivilege.DASHBOARD, UserPrivilege.MANAGEMENT]);
        if (userPrivileges.includes('createTickets')) {
            privileges.push(UserPrivilege.REMEDIATION);
        }
    }

    if (ticketingEnabled && ticketingSupported) {
        privileges.push(UserPrivilege.TICKETS);
    }

    if (isCloudEnabled && productEdition === ProductEdition.INSIGHT_VM) {
        privileges.push(UserPrivilege.GOALS);
    }

    // if the user does not have insight vm access e.g. its disabled dont show the container tab
    // or check the cloud status
    if (!disabledFeatures.includes('InsightVM') &&
        !disabledFeatures.includes('container') &&
        !disabledFeatures.includes('cca')) {
        if (isCloudEnabled && productEdition === ProductEdition.INSIGHT_VM) {
            privileges.push(UserPrivilege.CONTAINER);
            privileges.push(UserPrivilege.INFRASTRUCTURE);
        }
    }

    if (productEdition === ProductEdition.INSIGHT_VM && isAdmin) {
        privileges.push(UserPrivilege.AUTOMATION);
    }

    if (licensedOptions.includes('ADAPTIVE_SECURITY') && isAdmin) {
        privileges.push(UserPrivilege.ACTIONS);
    }

    if (canUserSeeAdminTab) {
        privileges.push(UserPrivilege.ADMIN);
    }

    if (userPrivileges.includes('manageSites')) {
        privileges.push(UserPrivilege.MANAGE_SITES);
    }

    if (userPrivileges.includes('createReports')) {
        privileges.push(UserPrivilege.CREATE_REPORTS);
    }

    if (userPrivileges.includes('manageAssetGroups')) {
        privileges.push(UserPrivilege.MANAGE_ASSET_GROUPS);
    }

    if (userPrivileges.includes('manageDynamicAssetGroups')) {
        privileges.push(UserPrivilege.MANAGE_DYNAMIC_ASSET_GROUPS);
    }

    if (userPrivileges.includes('manageTags')) {
        privileges.push(UserPrivilege.MANAGE_TAGS);
    }

    return privileges;
};
